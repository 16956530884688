import React, { ReactElement } from 'react';
import { graphql, PageProps } from 'gatsby';
import { LatestBlogPosts } from '../components/FeaturedBlogPosts/withQueryLatest';
import { UpcomingEventPosts } from '../components/FeaturedEventPosts/withQueryUpcoming';
import { RichTextData } from '../components/RichText';
import { TitleArea } from '../components/TemplateHelpers/1-TitleArea';
import { ContentArea } from '../components/TemplateHelpers/3-ContentArea';
import { Divider } from '../components/Divider';
import { Box } from '@chakra-ui/react';

type FrontpageQuery = {
  contentfulPageFrontpage: {
    title: string;
    subtitle?: string;
    content?: RichTextData;
    showTableOfContents?: boolean;
  };
};

export const Frontpage = ({
  data,
}: PageProps<FrontpageQuery>): ReactElement => {
  const { title, subtitle, content, showTableOfContents } =
    data.contentfulPageFrontpage;

  return (
    <>
      <TitleArea variant={'frontpage'} title={title} subtitle={subtitle} />
      <ContentArea
        variant={'frontpage'}
        content={content}
        showTableOfContents={showTableOfContents}
      />
      <Divider
        marginTop={{
          base: 40,
          s: `calc(${40}px + ${90 - 40} * (100vw - ${600}px) / ${1200 - 600})`,
          l: 90,
        }}
        marginBottom={{
          base: 20,
          s: `calc(${20}px + ${80 - 20} * (100vw - ${600}px) / ${1200 - 600})`,
          l: 80,
        }}
      />
      <LatestBlogPosts />
      <Box height={'1em'} />
      <UpcomingEventPosts />
    </>
  );
};

export default Frontpage;

export const query = graphql`
  query ($id: String!) {
    contentfulPageFrontpage(id: { eq: $id }) {
      ...Frontpage
    }
  }
`;
