import React, { ReactElement } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FeaturedBlogPosts } from '.';
import { LinkCardBlogPostProps } from '../LinkCardBlogPost';

type LatestBlogPostsQuery = {
  contentfulPageBlogCollection: {
    slug: string;
  };
  allContentfulPageBlogPost: {
    nodes: LinkCardBlogPostProps[];
  };
};

export const LatestBlogPosts = (): ReactElement => {
  const data: LatestBlogPostsQuery = useStaticQuery(query);
  return (
    <FeaturedBlogPosts
      parentSlug={data.contentfulPageBlogCollection.slug}
      posts={data.allContentfulPageBlogPost.nodes}
      heading={{
        en: 'Latest blog posts',
        fi: 'Uusimmat blogit',
      }}
    />
  );
};

const query = graphql`
  {
    contentfulPageBlogCollection {
      slug
    }
    allContentfulPageBlogPost(
      filter: { node_locale: { eq: "en" } }
      sort: { fields: createdAt, order: DESC }
      limit: 3
    ) {
      nodes {
        ... on ContentfulPageBlogPost {
          ...BlogPostLink
        }
      }
    }
  }
`;
