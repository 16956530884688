import { LinkCardEventPostProps } from '../LinkCardEventPost';

export const TRANS = {
  seeAll: {
    en: 'See all',
    fi: 'Katso kaikki',
  },
  seeAllPosts: {
    en: 'See all blog posts',
    fi: 'Katso kaikki blogit',
  },
};

export const MOCK_FEATURED_EVENT_POSTS: LinkCardEventPostProps[] = [
  {
    parentSlug: 'events',
    slug: 'a',
    language: 'en',
    title: 'Aaa',
    start: '2022-01-01T18:00:00.000',
    end: '2022-01-01T20:00:00.000',
    location: 'Turku',
    image: {
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=250&h=167&q=100&fm=webp 250w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=500&h=334&q=100&fm=webp 500w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=1000&h=667&q=100&fm=webp 1000w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=2000&h=1334&q=100&fm=webp 2000w',
              sizes: '(min-width: 1000px) 1000px, 100vw',
              type: 'image/webp',
            },
          ],
          fallback: {
            src: 'https://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=1000&h=667&fl=progressive&q=100&fm=jpg',
            srcSet:
              'https://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=250&h=167&fl=progressive&q=100&fm=jpg 250w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=500&h=334&fl=progressive&q=100&fm=jpg 500w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=1000&h=667&fl=progressive&q=100&fm=jpg 1000w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=2000&h=1334&fl=progressive&q=100&fm=jpg 2000w',
            sizes: '(min-width: 1000px) 1000px, 100vw',
          },
        },
        layout: 'constrained',
        width: 1000,
        height: 667,
      },
    },
  },
  {
    parentSlug: 'events',
    slug: 'b',
    language: 'en',
    title: 'Bbb',
    start: '2022-02-02T09:00:00.000',
    end: '2022-02-03T21:00:00.000',
    location: 'Helsinki',
    image: {
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=250&h=167&q=100&fm=webp 250w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=500&h=334&q=100&fm=webp 500w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=1000&h=667&q=100&fm=webp 1000w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=2000&h=1334&q=100&fm=webp 2000w',
              sizes: '(min-width: 1000px) 1000px, 100vw',
              type: 'image/webp',
            },
          ],
          fallback: {
            src: 'https://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=1000&h=667&fl=progressive&q=100&fm=jpg',
            srcSet:
              'https://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=250&h=167&fl=progressive&q=100&fm=jpg 250w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=500&h=334&fl=progressive&q=100&fm=jpg 500w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=1000&h=667&fl=progressive&q=100&fm=jpg 1000w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=2000&h=1334&fl=progressive&q=100&fm=jpg 2000w',
            sizes: '(min-width: 1000px) 1000px, 100vw',
          },
        },
        layout: 'constrained',
        width: 1000,
        height: 667,
      },
    },
  },
  {
    parentSlug: 'events',
    slug: 'c',
    language: 'en',
    title: 'Ccc',
    start: '2022-03-03T00:00:00.000',
    end: '2022-03-04T23:59:00.000',
    location: 'Tampere',
    image: {
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                'https://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=250&h=167&q=100&fm=webp 250w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=500&h=334&q=100&fm=webp 500w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=1000&h=667&q=100&fm=webp 1000w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=2000&h=1334&q=100&fm=webp 2000w',
              sizes: '(min-width: 1000px) 1000px, 100vw',
              type: 'image/webp',
            },
          ],
          fallback: {
            src: 'https://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=1000&h=667&fl=progressive&q=100&fm=jpg',
            srcSet:
              'https://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=250&h=167&fl=progressive&q=100&fm=jpg 250w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=500&h=334&fl=progressive&q=100&fm=jpg 500w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=1000&h=667&fl=progressive&q=100&fm=jpg 1000w,\nhttps://images.ctfassets.net/8xc8ns12po95/5jt2f4FbjkF2u7T1GiiswA/baeadc36c64615c4f8d31bdf150d79f5/photo-1479090793912-eb9929f4fdb2?w=2000&h=1334&fl=progressive&q=100&fm=jpg 2000w',
            sizes: '(min-width: 1000px) 1000px, 100vw',
          },
        },
        layout: 'constrained',
        width: 1000,
        height: 667,
      },
    },
  },
];
