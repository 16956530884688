import React, { ReactElement } from 'react';
import GatsbyLink from 'gatsby-link';
import { Box, Hide, Show, SimpleGrid, VisuallyHidden } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useLocale } from '../../utils/hooks';
import { Locale } from '../../utils/types';
import { Heading } from '../Heading';
import { Link } from '../Link';
import {
  LinkCardEventPost,
  LinkCardEventPostProps,
} from '../LinkCardEventPost';
import { NestedLocale } from '../NestedLocale';
import { TRANS } from './misc';

export type FeaturedEventPostsProps = {
  componentLocale?: Locale;
  heading?: {
    en: string;
    fi: string;
  };
  parentSlug?: string;
  posts?: LinkCardEventPostProps[];
  withoutCardImages?: boolean;
};

const SeeAll = ({
  componentLocale,
  parentSlug,
}: Pick<
  FeaturedEventPostsProps,
  'componentLocale' | 'parentSlug'
>): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);

  return !parentSlug ? null : (
    <Link
      as={GatsbyLink}
      to={`/${locale}/${parentSlug}`}
      lang={lang}
      variant={'large'}
      sx={{
        '& .text, & .icon': { transitionDuration: 'normal' },
        '&:hover .text': { textDecoration: 'underline' },
        '&:hover .icon': { transform: 'translate(0.3em, 0)' },
      }}
    >
      <Box as={'span'} className={'text'}>
        <span aria-hidden>{TRANS.seeAll[locale]}</span>
        <VisuallyHidden> {TRANS.seeAllPosts[locale]}</VisuallyHidden>
      </Box>
      <ArrowForwardIcon
        className={'icon'}
        aria-hidden
        fontSize={'1.2em'}
        marginTop={'-0.2em'}
        marginLeft={'0.2em'}
      />
    </Link>
  );
};

export const FeaturedEventPosts = ({
  componentLocale,
  heading = {
    en: 'Events',
    fi: 'Tapahtumat',
  },
  parentSlug,
  posts,
  withoutCardImages,
}: FeaturedEventPostsProps): ReactElement | null => {
  const { locale, lang } = useLocale(componentLocale);

  return !posts?.length ? null : (
    <NestedLocale componentLocale={lang}>
      <Show above={'m'}>
        <Heading level={3} as={'h2'}>
          {heading[locale]}
        </Heading>
        <SimpleGrid
          gap={'1em'}
          gridTemplateColumns={`repeat(${Math.min(posts.length, 3)}, 1fr)`}
          marginTop={'1.5em'}
          marginBottom={'1em'}
          sx={{
            '& .image':
              posts.length === 1
                ? {
                    width: 'calc((100% - 1em) / 2)',
                  }
                : {},
            '& .text': {
              paddingTop: '0.75em',
            },
          }}
        >
          {posts.map((post, i) => (
            <LinkCardEventPost
              key={post.title + i}
              {...post}
              parentSlug={parentSlug}
              variant={
                posts.length === 1
                  ? withoutCardImages
                    ? 'wideWithoutImage'
                    : 'wide'
                  : withoutCardImages
                  ? 'tallWithoutImage'
                  : 'tall'
              }
            />
          ))}
        </SimpleGrid>
        <SeeAll parentSlug={parentSlug} />
      </Show>
      <Hide above={'m'}>
        <Heading level={3} as={'h2'}>
          {heading[locale]}
        </Heading>
        <SimpleGrid
          gap={'1em'}
          marginY={'1em'}
          sx={{
            '& .text': {
              paddingTop: '0.7em',
            },
          }}
        >
          {posts.map((post, i) => (
            <LinkCardEventPost
              key={post.title + i}
              {...post}
              parentSlug={parentSlug}
              variant={withoutCardImages ? 'wideWithoutImage' : 'wide'}
            />
          ))}
        </SimpleGrid>
        <SeeAll parentSlug={parentSlug} />
      </Hide>
    </NestedLocale>
  );
};
