export const TRANS = {
  seeAll: {
    en: 'See all',
    fi: 'Katso kaikki',
  },
  seeAllPosts: {
    en: 'See all blog posts',
    fi: 'Katso kaikki blogit',
  },
};
