import React, { ReactElement, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { FeaturedEventPosts } from '.';
import { LinkCardEventPostProps } from '../LinkCardEventPost';

type UpcomingEventPostsQuery = {
  contentfulPageEventCollection: {
    slug: string;
  };
  allContentfulPageEventPost: {
    nodes: LinkCardEventPostProps[];
  };
};

export const UpcomingEventPosts = (): ReactElement => {
  const data: UpcomingEventPostsQuery = useStaticQuery(query);
  const nextThree = useMemo(
    () =>
      data.allContentfulPageEventPost.nodes
        .filter((post) => new Date(post.end).getTime() - Date.now() > 0)
        .slice(0, 3),
    [data]
  );

  return (
    <FeaturedEventPosts
      parentSlug={data.contentfulPageEventCollection.slug}
      posts={nextThree}
      heading={{
        en: 'Upcoming events',
        fi: 'Tulevat tapahtumat',
      }}
    />
  );
};

const query = graphql`
  {
    contentfulPageEventCollection {
      slug
    }
    allContentfulPageEventPost(
      filter: { node_locale: { eq: "en" } }
      sort: { fields: start, order: ASC }
    ) {
      nodes {
        ... on ContentfulPageEventPost {
          ...EventPostLink
        }
      }
    }
  }
`;
